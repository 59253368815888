import React from "react";

import Top from "./sections/S0_Top";
import Cover from "./sections/S1_Cover";
import Links from "./sections/S2_Links";
import Why from "./sections/S3_Why";
import Vision from "./sections/S4_Vision";
import Space from "./sections/S5_Space";
import Steps from "./sections/S6_Steps";
import Contact from "./sections/S7_Contact";

function Home() {
  const [lang, setLang] = React.useState("default");

  return (
    <>
      <Top {...{ lang, setLang }} />
      <Cover lang={lang} />
      <Links lang={lang} />
      <Why lang={lang} />
      <Vision lang={lang} />
      <Space lang={lang} />
      <Steps lang={lang} />
      <Contact lang={lang} />
    </>
  );
}

export default Home;
