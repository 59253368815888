import { BREAKPOINTS as BPK, LANGS as LGK } from "./keys";

export const BREAKPOINTS_WIDTHS = {
  [BPK.MONITOR]: 1500,
  [BPK.WIDE_LAPTOP]: 1280,
  [BPK.LAPTOP]: 1024,
  [BPK.LANDSCAPE]: 768,
  [BPK.TABLET]: 550,
  [BPK.PHONE]: 350,
  [BPK.SMALL_PHONE]: 0,
};

export const CONTACT_INFO = {
  VERBOSE_PHONE: "+54 9 388 4 7865-59",
  JOINED_PHONE: "5493884786559",
  EMAIL_ADDRESS: "atencion@kamai.com.ar",
  WEBSITE_URL: "https://www.kamai.com.ar/",
  SOCIALS: {
    FACEBOOK: "https://www.facebook.com/kamaiWeb",
    INSTAGRAM: "https://www.instagram.com/kamai_web/",
  },
};

export const LANGS = {
  [LGK.DEFAULT]: {
    name: "Spanish",
    emoji: "🇪🇸",
  },
};
