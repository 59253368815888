import React from "react";
import { Element as ScrollableContainer } from "react-scroll";
import { BsWhatsapp, BsEnvelope, BsCheck2Circle } from "react-icons/bs";

import { CuteButton } from "@common/index";
import { useBreakpoint } from "@static/react";

import { BREAKPOINTS } from "@static/values/keys";
import { CONTACT_INFO } from "@static/values/config";

function Steps({ lang }) {
  const bp = useBreakpoint();

  return (
    <ScrollableContainer name="section-steps" className={STYLES.ct}>
      <p className={STYLES.title}>
        {TRANSLATE_TEXT.title[lang]} <br />
        <span className={STYLES.titleColored}>
          {TRANSLATE_TEXT.titleColored[lang]}
        </span>
      </p>

      <div className={STYLES.step}>
        <p className={STYLES.stepNumber}>1</p>
        <div className={STYLES.stepContent}>
          <p className={STYLES.stepTitle}>{TRANSLATE_TEXT.step1.title[lang]}</p>
          <p className={STYLES.stepText}>{TRANSLATE_TEXT.step1.text[lang]}</p>
          <div className={STYLES.step1Bottom}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://api.whatsapp.com/send/?phone=${CONTACT_INFO.JOINED_PHONE}&text=Estoy%20en%20la%20web%20de%20Kamai%20y%20me%20gustaría%20consultar%20algo:%20`}
              className="mr-2"
            >
              <CuteButton
                Icon={BsWhatsapp}
                color="emerald"
                colorsBehavior="always-filled"
                straightStyles={{ icon: "mt-1px" }}
                size={
                  bp.isWiderThan(BREAKPOINTS.PHONE)
                    ? "default"
                    : bp.isWiderThan(BREAKPOINTS.LANDSCAPE)
                    ? "larger"
                    : "smaller"
                }
              >
                WhatsApp
              </CuteButton>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${CONTACT_INFO.EMAIL_ADDRESS}`}
            >
              <CuteButton
                Icon={BsEnvelope}
                color="sky"
                colorsBehavior="always-filled"
                straightStyles={{ icon: "mt-1px" }}
                size={
                  bp.isWiderThan(BREAKPOINTS.PHONE)
                    ? "default"
                    : bp.isWiderThan(BREAKPOINTS.LANDSCAPE)
                    ? "larger"
                    : "smaller"
                }
              >
                {TRANSLATE_TEXT.step1.mail[lang]}
              </CuteButton>
            </a>
          </div>
        </div>
      </div>

      {STEPS.map((step) => (
        <div key={step.number} className={STYLES.step}>
          <p className={STYLES.stepNumber}>{step.number}</p>
          <div className={STYLES.stepContent}>
            <p className={STYLES.stepTitle}>{step.title[lang]}</p>
            <p className={STYLES.stepText}>{step.text[lang]}</p>
          </div>
        </div>
      ))}

      <div className={STYLES.confirmed}>
        <BsCheck2Circle className={STYLES.confirmedIcon} />
        {TRANSLATE_TEXT.confirmed[lang]}
      </div>

      {INVERSE_STEPS.map((step) => (
        <div key={step.number} className={STYLES.step + STYLES.stepInverse}>
          <div className={STYLES.stepContent + STYLES.stepContentInverse}>
            <p className={STYLES.stepTitle}>{step.title[lang]}</p>
            <p className={STYLES.stepText}>{step.text[lang]}</p>
          </div>
          <p className={STYLES.stepNumber + STYLES.stepNumberInverse}>
            {step.number}
          </p>
        </div>
      ))}

      <p className={STYLES.bottomTitle}>{TRANSLATE_TEXT.bottomTitle[lang]}</p>
      <p className={STYLES.bottomText}>{TRANSLATE_TEXT.bottomText[lang]}</p>

      <div style={LINES_POSITIONING.line1[bp.name]} className={STYLES.line1} />
      <div className={STYLES.cover1} />
      {/* <MdArrowBack style={{ top: "10.45rem" }} className={STYLES.arrow1} /> */}
      <div style={LINES_POSITIONING.line2[bp.name]} className={STYLES.line2} />

      <div style={LINES_POSITIONING.line3[bp.name]} className={STYLES.line3} />
      <div className={STYLES.cover2} />
      {/* <MdArrowBack style={{ top: "83.5rem" }} className={STYLES.arrow2} /> */}
      <div style={LINES_POSITIONING.line4[bp.name]} className={STYLES.line4} />

      <div className={STYLES.ornament}>
        <div className={STYLES.ornamentInside} />
      </div>
      <div className={STYLES.ornament2}>
        <div className={STYLES.ornament2Inside} />
      </div>
    </ScrollableContainer>
  );
}

//prettier-ignore
const STYLES = {
  ct: "relative pt-10 pb-16 bg-slate-100 flex flex-col overflow-hidden",
  title: "text-center text-slate-600 text-strong text-3xl mb-12 | sm:text-4xl | lg:text-4.5xl",
  titleColored: "text-kamai",

  step: "relative z-50 mt-12 flex items-start w-88 pl-2 | sm:pl-8 sm:w-136 | md:pl-20 md:w-190 | lg:pl-30 lg:w-210 lg:mt-16 ",
  stepNumber: "shrink-0 flex mt-2 justify-center items-center pb-1px w-9 h-9 rounded-full bg-kamai leading-none text-slate-100 text-1.5xl text-stronger mr-3 | sm:mr-5 | md:w-11 md:text-2xl md:h-11 md:mr-6 | lg:mr-8 lg:h-12 lg:w-12 ",
  stepContent: "",
  stepTitle: "text-slate-700 text-xl text-strong | sm:text-1.5xl | lg:text-2xl",
  stepText: "text-very-light text-base text-slate-600 mt-1 | sm:text-lg sm:mt-2 | lg:text-xl",

  confirmed: "relative z-50 flex justify-center mx-auto text-lg items-center -mb-1 mt-9 w-60 text-light bg-slate-100 text-kamai | sm:w-75 sm:mt-10 sm:text-xl | md:mb-0 md:mt-16 | lg:text-2xl lg:w-90",
  confirmedIcon: "mr-2 text-1.5xl | sm:mr-4 sm:text-2xl | lg:text-3xl",

  stepInverse: "self-end pl-0 pr-2 | sm:!pl-0 sm:pr-8 | md:!pl-0 md:pr-20 | lg:!pl-0 lg:pr-30",
  stepNumberInverse: "mr-0 ml-3 | sm:!mr-0 sm:ml-5 | md:!mr-0 md:ml-6 | lg:!mr-0 lg:ml-8",
  stepContentInverse: "text-right", 

  step1Bottom: "flex mt-3 | sm:mt-4",

  bottomTitle: "text-kamai text-center mt-22 text-1.5xl text-strong | sm:mt-24 sm:text-2xl | md:mt-28 md:text-2.5xl | lg:text-3xl",
  bottomText: "mt-1 text-sm text-slate-500 text-very-light text-center w-80 mx-auto | sm:w-110 sm:text-base | lg:text-lg lg:mt-2",

  line1: "z-10 absolute left-0 w-6/12 h-10 border-r-2 border-b-2 rounded-br-2xl border-kamai-light",
  cover1: "z-20 absolute top-41 w-3/12 h-6 bg-slate-100 left-0 | sm:top-47 | lg:top-52",
  // arrow1: "z-40 absolute left-30 text-2xl bg-slate-100 text-kamai px-1",
  line2: "z-30 absolute w-4/12 h-159 border-l-2 border-y-2 rounded-l-3xl border-kamai-light | sm:h-156 | md:h-141 | lg:h-168",

  line3: "z-30 absolute w-4/12 h-148 border-r-2 border-y-2 rounded-r-3xl border-kamai-light | sm:h-143 | md:h-134 | lg:h-154",
  cover2: "z-20 absolute bottom-42 w-3/12 h-6 bg-slate-100 right-0 | sm:bottom-48 | lg:bottom-52",
  // arrow2: "z-40 absolute right-30 text-2xl bg-slate-100 text-kamai px-1",
  line4 : "z-10 absolute w-6/12 right-0 h-10 border-l-2 border-t-2 rounded-tl-2xl border-kamai-light",

  ornament: "absolute flex justify-center items-center h-50 w-50 bg-kamai-light bg-opacity-20 top-15 -right-35 rounded-full | sm:top-70 sm:-right-50 sm:w-70 sm:h-70 | md:w-80 md:h-80 | lg:-right-80 lg:w-130 lg:h-130",
  ornamentInside: "absolute w-30 h-30 top-15 left-5 bg-slate-100 rounded-full | sm:top-17 sm:left-10 sm:w-50 sm:h-50 | md:left-12 sm:top-19 md:w-58 md:h-58 | lg:left-20 lg:top-25 lg:w-100 lg:h-100",

  ornament2: "absolute flex items-center h-95 w-95 bg-kamai-light bg-opacity-20 bottom-65 -left-70 rounded-full | sm:bottom-65 sm:-left-65 | md:w-110 md:h-110 | lg:bottom-40 lg:-left-90 lg:w-150 lg:h-150",
  ornament2Inside: "absolute top-10 -right-20 h-60 w-60 bg-slate-100 rounded-full | md:h-75 md:w-75 | lg:w-100 lg:h-100 ",
};

const LINES_POSITIONING = {
  line1: {
    [BREAKPOINTS.SMALL_PHONE]: { top: "8.77rem" },
    [BREAKPOINTS.PHONE]: { top: "8.78rem" },
    [BREAKPOINTS.TABLET]: { top: "10rem" },
    [BREAKPOINTS.LANDSCAPE]: { top: "10rem" },
    [BREAKPOINTS.LAPTOP]: { top: "11.45rem" },
    [BREAKPOINTS.WIDE_LAPTOP]: { top: "11.45rem" },
  },
  line2: {
    [BREAKPOINTS.SMALL_PHONE]: { top: "11.16rem", left: "25px" },
    [BREAKPOINTS.PHONE]: { top: "11.16rem", left: "25px" },
    [BREAKPOINTS.TABLET]: { top: "12.38rem", left: "49px" },
    [BREAKPOINTS.LANDSCAPE]: { top: "12.38rem", left: "101px" },
    [BREAKPOINTS.LAPTOP]: { top: "13.85rem", left: "143px" },
    [BREAKPOINTS.WIDE_LAPTOP]: { top: "13.85rem", left: "143px" },
  },
  line3: {
    [BREAKPOINTS.SMALL_PHONE]: { top: "49.4rem", right: "25px" },
    [BREAKPOINTS.PHONE]: { top: "50.8rem", right: "25px" },
    [BREAKPOINTS.TABLET]: { top: "51.25rem", right: "49px" },
    [BREAKPOINTS.LANDSCAPE]: { top: "47.6rem", right: "101px" },
    [BREAKPOINTS.LAPTOP]: { top: "55.75rem", right: "143px" },
    [BREAKPOINTS.WIDE_LAPTOP]: { top: "55.75rem", right: "143px" },
  },
  line4: {
    [BREAKPOINTS.SMALL_PHONE]: { top: "84.27rem" },
    [BREAKPOINTS.PHONE]: { top: "87.7rem" },
    [BREAKPOINTS.TABLET]: { top: "86.95rem" },
    [BREAKPOINTS.LANDSCAPE]: { top: "81rem" },
    [BREAKPOINTS.LAPTOP]: { top: "94.15rem" },
    [BREAKPOINTS.WIDE_LAPTOP]: { top: "94.15rem" },
  },
};

const TRANSLATE_TEXT = {
  title: {
    default: "Cómo conseguir tu",
  },
  titleColored: {
    default: "Web Personalizada",
  },
  confirmed: {
    default: "Encargo confirmado",
  },
  bottomTitle: {
    default: "¡ Eso es todo !",
  },
  bottomText: {
    default:
      "Podrás compartir el link a tu web en cualquier parte con sólo copiar y pegar.",
  },
  step1: {
    title: { default: "Contactate con nosotros" },
    text: {
      default:
        "Envianos un mensaje a través de WhatsApp o un correo electrónico, solicitando tu página web. Allí te proveeremos de un presupuesto general.",
    },
    mail: { default: "Correo" },
  },
};

const STEPS = [
  {
    number: 2,
    title: { default: "Reunión de personalización" },
    text: {
      default:
        "Si decidís avanzar, realizaremos una breve reunión para que nos expliques la idea general de tu página y cómo te gustaría.",
    },
  },
  {
    number: 3,
    title: { default: "Presupuesto detallado" },
    text: {
      default:
        " Unas horas después recibirás el presupuesto detallado de la página que solicitaste para que puedas decidir si continuar. Sin compromisos.",
    },
  },
];

const INVERSE_STEPS = [
  {
    number: 4,
    title: { default: "Inicio del trabajo" },
    text: {
      default:
        "Una vez confirmes el trabajo y abones la primera cuota, comenzaremos a trabajar.",
    },
  },
  {
    number: 5,
    title: { default: "Revisiones continuas" },
    text: {
      default:
        "Cada semana te mostraremos el progreso de tu página para que puedas revisarla, hacer correcciones y agregar más componentes que te gustarían, y así asegurarte de recibirla tal cual la imaginabas.",
    },
  },
  {
    number: 6,
    title: { default: "Entrega de tu web" },
    text: {
      default:
        "Te entregaremos el link a tu web en la fecha acordada en el presupuesto general que te enviamos. Te garantizamos la fecha.",
    },
  },
];

export default Steps;
