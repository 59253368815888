import React from "react";
import { Element as ScrollableContainer } from "react-scroll";
import { MdArrowForwardIos, MdOutlinePhonelink } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import { AiOutlineCloudServer } from "react-icons/ai";
import { RiPagesLine } from "react-icons/ri";
import { TbWorldWww } from "react-icons/tb";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { BgContainer } from "@common/index";
import { useBreakpoint } from "@static/react";

import { BREAKPOINTS_WIDTHS } from "@static/values/config";
import { BREAKPOINTS as BPK } from "@static/values/keys";

function Proyects({ lang }) {
  const bp = useBreakpoint();
  const carouselRef = React.useRef();

  return (
    <ScrollableContainer name="section-why">
      <BgContainer
        bgClassName={STYLES.bg}
        imgSrc={BACKGROUND}
        className={STYLES.ct}
        overlay="very-strong"
      >
        <p className={STYLES.title}>
          {bp.isDesktop() ? (
            <>
              {TRANSLATE_TEXT.titleDesktop[lang]} <br />
              <span className={STYLES.title2}>
                {TRANSLATE_TEXT.title2Desktop[lang]}
              </span>
            </>
          ) : (
            <>
              {TRANSLATE_TEXT.title[lang]}
              {bp.isWiderThan(BPK.PHONE) ? " " : <br />}
              <span className={STYLES.title2}>
                {TRANSLATE_TEXT.title2[lang]}
              </span>
            </>
          )}
        </p>
        {/* <div className={STYLES.separator} /> */}

        <div className={STYLES.list}>
          <button
            onClick={() => carouselRef.current.slidePrev()}
            className={STYLES.arrowCt + STYLES.arrowLeft}
          >
            <MdArrowForwardIos className={STYLES.arrow} />
          </button>
          <AliceCarousel
            mouseTracking
            responsive={{
              0: { items: 1 },
              [BREAKPOINTS_WIDTHS[BPK.LANDSCAPE]]: { items: 2 },
              [BREAKPOINTS_WIDTHS[BPK.WIDE_LAPTOP]]: { items: 3 },
            }}
            ref={carouselRef}
            items={ITEMS.map((item) => (
              <div className={STYLES.item}>
                <p className={STYLES.itemIcon}>
                  <item.Icon className={item.iconStyle} />
                </p>
                <p className={STYLES.itemTitle}>{item.title[lang]}</p>
                <p className={STYLES.itemText}>{item.text[lang]}</p>
              </div>
            ))}
            disableButtonsControls
            disableDotsControls
            infinite
          />
          <button
            onClick={() => carouselRef.current.slideNext()}
            className={STYLES.arrowCt + STYLES.arrowRight}
          >
            <MdArrowForwardIos className={STYLES.arrow} />
          </button>
        </div>
      </BgContainer>
    </ScrollableContainer>
  );
}

//prettier-ignore
const STYLES = {
  ct: "relative pt-12 pb-12 flex flex-col items-center | lg:py-16 | xl:py-20",
  bg: "bg-cover bg-center",
  title: "text-center text-slate-300 text-strong text-4.5xl mb-4 | md:w-140",
  title2: "text-kamai-light",
  separator: "mt-6 w-30 border-t-2 border-slate-200 mx-auto",

  list: "relative w-full | md:px-10 | lg:px-14 | xl:px-20",

  arrowCt: "z-50 absolute top-32 cursor-pointer text-slate-300 p-3 rounded-full transition duration-300 border-slate-300 border-opacity-30 border-1 hover:border-opacity-80 focus:border-opacity-80 | md:top-40 | xl:p-4",
  arrow: "translate-x-1px text-lg | xl:text-xl",
  arrowLeft: " rotate-180 left-4 | sm:left-8 | xl:left-12 ",
  arrowRight: " right-4 | sm:right-8 | xl:right-12",

  item: "relative flex flex-col items-center mx-4 pt-8 | md:mx-6 md:pt-12",
  itemIcon: "w-38 h-38 flex justify-center items-center text-6.5xl rounded-full border-2 border-opacity-70 border-kamai-light text-slate-300 | sm:w-42 sm:h-42 sm:text-7xl | lg:w-46 lg:h-46 ",
  itemTitle: "mt-8 text-2xl text-strong text-slate-300 text-center tracking-wide",
  itemText: "mt-8 pb-4 text-base text-very-light text-slate-300 text-center tracking-wide !leading-normal break-words text-left w-85 | sm:text-lg sm:w-120 | md:w-85 md:w-auto | lg:w-110 | xl:w-90",
};

const TRANSLATE_TEXT = {
  title: {
    default: "Impulsá tu",
  },
  titleDesktop: {
    default: "Impulsá tu crecimiento",
  },
  title2: {
    default: "crecimiento",
  },
  title2Desktop: {
    default: "con una página web",
  },
};

const BACKGROUND = "https://i.ibb.co/cXc0SgV/kamai-web-why.jpg";

const ITEMS = [
  {
    Icon: MdOutlinePhonelink,
    title: { default: "Usalo desde cualquier lugar." },
    text: {
      default:
        "Tener tu sistema en internet te permite gestionar todo desde cualquier dispositivo, en cualquier parte y a cualquier hora. No más sistemas locales en oficinas.",
    },
  },
  {
    Icon: AiOutlineCloudServer,
    title: { default: "Más estable, y sin máquinas." },
    text: {
      default:
        "Un sistema web, alojado en la nube, es inmune a fallas físicas que pueden acontecer en una oficina o lugar real. Además, no necesitas adquirir computadoras ni equipo adicional.",
    },
  },
  {
    Icon: RiPagesLine,
    title: { default: "Presentación digital ideal." },
    text: {
      default:
        "¿Necesitas mostrar digitalmente tu experiencia y servicios? Una página web es tu espacio ideal 100% personalizado. Aumentará drásticamente tu alcance y atraerá nuevos clientes o empleadores.",
    },
  },
  {
    Icon: BsShareFill,
    title: { default: "Compartir fácil y al instante." },
    text: {
      default:
        "Nada de pendrive, nada de enviar ni descargar archivos, nada de descargar Apps. Para compartir tu página sólo necesitas copiar y pegar el link, ¡y listo! Ya sea por WhatsApp, redes sociales, correo, o donde quieras.",
    },
    iconStyle: "mr-2",
  },
  {
    Icon: FaUserTie,
    title: { default: "La mejor impresión." },
    text: {
      default:
        "Una web personalizada inspira compromiso, seriedad y calidad. Generá confianza y atraé nuevos clientes o empleadores con tu página a medida.",
    },
  },
  {
    Icon: TbWorldWww,
    title: { default: "Tu espacio en internet." },
    text: {
      default:
        "Tu sitio web es un espacio sin límites en Internet, para vos o tu negocio. Cualquier funcionalidad o presentación que imagines puede tener su lugar allí. No te limites, y hacé tu sistema web.",
    },
  },
];

export default Proyects;
