import React from "react";
import { Element as ScrollableContainer } from "react-scroll";
import {
  BsWhatsapp,
  BsDot,
  BsEnvelopeFill,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";
import { TbArrowWaveRightUp } from "react-icons/tb";

import { BgContainer } from "@common/index";
import { useBreakpoint, useBordersVisibility } from "@static/react";
import { useIndicatedStyles } from "@static/tailwind";

import { CONTACT_INFO } from "@static/values/config";

function Contact({ lang }) {
  //prettier-ignore
  const getActiveStyles = useIndicatedStyles(INDICATORS, DIRECTED_STYLES);

  const bp = useBreakpoint();
  const visible = useBordersVisibility({ pixelsForVisibleBottom: 30 });

  const styles = getActiveStyles({ visible });

  const layout = bp.isDesktop() ? (
    <>
      <div className={styles.itemsList}>
        <div className={styles.line}>
          <BsEnvelopeFill className={styles.lineIcon} />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${CONTACT_INFO.EMAIL_ADDRESS}`}
            className={styles.lineLink}
          >
            {CONTACT_INFO.EMAIL_ADDRESS}
          </a>
        </div>
        <BsDot className={styles.lineDot} />
        <div className={styles.line}>
          <BsWhatsapp className={styles.lineIcon} />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send/?phone=${CONTACT_INFO.JOINED_PHONE}&text=Estoy%20en%20la%20web%20de%20Kamai%20y%20me%20gustaría%20consultar%20algo:%20`}
            className={styles.lineLink}
          >
            {CONTACT_INFO.VERBOSE_PHONE}
          </a>
        </div>
      </div>

      <div className={styles.socials}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_INFO.SOCIALS.FACEBOOK}
        >
          <BsFacebook className={styles.socialIcon} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_INFO.SOCIALS.FACEBOOK}
        >
          Facebook
        </a>
        <BsDot className={styles.lineDot} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_INFO.SOCIALS.INSTAGRAM}
        >
          <BsInstagram className={styles.socialIcon} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_INFO.SOCIALS.INSTAGRAM}
        >
          Instagram
        </a>
      </div>

      <p className={styles.text}>{TRANSLATE_TEXT.bottom[lang]}</p>
    </>
  ) : (
    <>
      <div className={styles.line}>
        <BsEnvelopeFill className={styles.lineIcon} />
        <BsDot className={styles.lineDot} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${CONTACT_INFO.EMAIL_ADDRESS}`}
          className={styles.lineLink}
        >
          {CONTACT_INFO.EMAIL_ADDRESS}
        </a>
      </div>

      <div className={styles.line}>
        <BsWhatsapp className={styles.lineIcon} />
        <BsDot className={styles.lineDot} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://api.whatsapp.com/send/?phone=${CONTACT_INFO.JOINED_PHONE}&text=Estoy%20en%20la%20web%20de%20Kamai%20y%20me%20gustaría%20consultar%20algo:%20`}
          className={styles.lineLink}
        >
          {CONTACT_INFO.VERBOSE_PHONE}
        </a>
      </div>

      <p className={styles.text}>{TRANSLATE_TEXT.bottom[lang]}</p>

      <div className={styles.socials}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_INFO.SOCIALS.FACEBOOK}
        >
          <BsFacebook className={styles.socialIcon} />
        </a>
        <BsDot className={styles.lineDot} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_INFO.SOCIALS.INSTAGRAM}
        >
          <BsInstagram className={styles.socialIcon} />
        </a>
      </div>
    </>
  );

  return (
    <ScrollableContainer name="section-contact">
      <BgContainer
        imgSrc={BACKGROUND}
        overlay="medium"
        bgClassName={styles.bg}
        className={styles.ct}
      >
        <p className={styles.title}>{TRANSLATE_TEXT.title[lang]}</p>
        <div className={styles.separator} />

        {layout}

        {/* <div className={styles.ornament}>
          <div className={styles.ornamentInside} />
        </div> */}

        <p className={styles.goTop}>
          ir arriba <TbArrowWaveRightUp className={styles.goTopIcon} />
        </p>
      </BgContainer>
    </ScrollableContainer>
  );
}

//prettier-ignore
const DIRECTED_STYLES = {
  ct: "relative pt-18 pb-24 px-6 overflow-hidden",
  bg: "bg-center bg-cover bg-no-repeat",

  title: "text-center text-slate-300 text-strong text-4xl mb-4 | sm:text-5xl | lg:text-5.5xl",      
  separator: "mt-6 w-30 border-t-2 border-kamai-light mx-auto mb-10 | sm:mb-12 | md:mb-14",

  line: "flex items-center justify-center text-slate-300 mt-3 px-4 tracking-wide text-lg | sm:mt-4 sm:text-xl | md:px-0 md:mt-0 | lg:text-1.5xl",
  lineDot: "text-lg mx-1 text-slate-300 | sm:mx-2 | md:mx-4",
  lineIcon: "text-2xl | sm:text-3xl | md:mr-4",
  lineLink: "border-b-1 pb-3px border-slate-300 border-opacity-70 hover:border-opacity-100 focus:border-opacity-100",

  text: "mt-10 text-light text-slate-300 text-opacity-90 w-80 mx-auto text-center | sm:text-xl | md:mt-12 | lg:w-auto lg:mt-14 lg:text-1.5xl",

  socials: "mt-10 w-30 mx-auto flex items-center justify-between text-xl text-slate-300 | sm:w-35 | md:w-95 md:mt-8 | lg:text-1.5xl lg:mt-10",
  socialIcon: "text-4xl text-slate-300 | sm:text-4.5xl | md:text-4xl",
 
  ornament: "absolute flex justify-center items-center h-50 w-50 bg-kamai-light bg-opacity-40 -bottom-30 -left-10 rounded-full | md:-left-20 md:h-70 md:w-70",
  ornamentInside: "absolute w-30 h-30 -top-10 left-0 bg-kamai-light bg-opacity-20 rounded-full | md:-top-15 md:w-50 md:h-50",

  goTop: "fixed bottom-5 right-16 text-slate-300 text-opacity-90 flex items-center text-lg text-light text-italic translate-y-20 transition duration-1000 | sm:bottom-7 sm:right-20 sm:text-xl || bo<translate-y-0>",
  goTopIcon: "ml-2 text-2xl mt-1",

  //Desktop only

  itemsList: "md:flex md:justify-center md:items-center",
};

const INDICATORS = [
  { key: "bottomVisible", directive: "bo", condition: (p) => p.visible.bottom },
];

const TRANSLATE_TEXT = {
  title: {
    default: "Contactémonos",
  },
  bottom: {
    default: "¿Tenés alguna duda? ¡No dudes en consultarnos! Sin compromisos.",
  },
};

const BACKGROUND = "https://i.ibb.co/YBJkgvm/kamai-web-contact.jpg";

export default Contact;
