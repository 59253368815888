import React from "react";
import { Element as ScrollableContainer } from "react-scroll";

import { BgContainer } from "@common/index";

import { BREAKPOINTS } from "@static/values/keys";
import { useBreakpoint } from "@static/react";

function Space({ lang }) {
  const bp = useBreakpoint();

  return (
    <ScrollableContainer name="section-space">
      <BgContainer
        className={STYLES.ct}
        bgClassName={STYLES.bgTop}
        overlay={bp.isDesktop() ? "medium" : ""}
        imgSrc={BACKGROUND.TOP}
      >
        <p className={STYLES.title}>{TRANSLATE_TEXT.title[lang]}</p>
        <div className={STYLES.separator} />

        <p className={STYLES.text}>{TRANSLATE_TEXT.text[lang]}</p>

        <p className={STYLES.text2}>
          {TRANSLATE_TEXT.text2[lang].split(". ").map((line, index) => (
            <React.Fragment key={index}>
              {line}.
              <br />
            </React.Fragment>
          ))}
        </p>
      </BgContainer>

      {!bp.isDesktop() && (
        <BgContainer imgSrc={BACKGROUND.BOTTOM} bgClassName={STYLES.bgBottom} />
      )}
    </ScrollableContainer>
  );
}

//prettier-ignore
const STYLES = {
  ct: "relative overflow-hidden pt-20 | sm:pb-12 | md:pb-26 | xl:pt-24 xl:pb-30",
  bgTop: "bg-cover bg-top bg-no-repeat",

  title: "text-center text-slate-300 text-strong mx-auto text-3.5xl w-88 | sm:w-120 | md:text-4xl | lg:text-4.5xl lg:w-auto | xl:text-5xl",
  separator: "mt-8 w-30 border-t-2 border-slate-200 mx-auto",

  text: "text-very-light mt-12 text-center mx-auto text-slate-300 text-lg w-88 tracking-wide leading-loose text-shadow strongest | sm:w-110 | md:mt-12 md:text-xl md:w-150 | lg:text-1.5xl lg:w-180 | xl:text-2xl xl:mt-14 xl:w-200",
  text2: "text-light mt-12 text-center mx-auto text-kamai-lighter text-xl tracking-wide leading-loose text-shadow strongest | md:text-1.5xl | lg:text-2xl | xl:mt-14 xl:text-2.5xl",

  bgBottom: "-mt-1 bg-bottom bg-cover bg-no-repeat h-70 w-full | sm:h-85 | md:h-110 | lg:h-145 | xl:h-170",
};

const TRANSLATE_TEXT = {
  title: { default: "Tu espacio sin límites" },
  text: {
    default:
      "Nuestros equipos de desarrollo y diseño construyen las páginas de Kamai desde el código base, sin plantillas y sin componentes preestablecidos que puedan poner límites.",
  },
  text2: {
    default:
      "Cualquier sistema que necesites. Cualquier presentación que te guste. Tan personalizado como quieras",
  },
};

const BACKGROUND = {
  TOP: "https://i.ibb.co/Z1xFVMh/kamai-web-space-top.jpg",
  BOTTOM: "https://i.ibb.co/FgbZdRz/kamai-web-space-bottom.jpg",
};

export default Space;
