import React from "react";
import { BsArrowDown } from "react-icons/bs";
import { scroller } from "react-scroll";

import { BgContainer } from "@common/index";
import { useBreakpoint } from "@static/react";

function Cover({ lang }) {
  const bp = useBreakpoint();

  function onArrowClick() {
    scroller.scrollTo("section-experience", {
      smooth: true,
      duration: 1000,
      offset: -50,
    });
  }

  return (
    <BgContainer
      bgClassName={STYLES.bg}
      className={STYLES.ct}
      imgSrc={bp.isDesktop() ? BACKGROUND.DESKTOP : BACKGROUND.MOBILE}
      overlay={bp.isDesktop() ? "light" : "medium"}
    >
      <Title lang={lang} />

      <p className={STYLES.subTitle}>{TRANSLATE_TEXT.subTitle[lang]}</p>

      <BsArrowDown onClick={onArrowClick} className={STYLES.arrow} />
    </BgContainer>
  );
}

function Title({ lang }) {
  const [titleIndex, setTitleIndex] = React.useState(0);

  React.useEffect(() => {
    const i = setInterval(
      () => setTitleIndex((prev) => (prev < TITLES.length - 1 ? prev + 1 : 0)),
      2000
    );
    return () => clearInterval(i);
  }, []);

  return (
    <p className={STYLES.title}>
      {TRANSLATE_TEXT.title[lang]} {TITLES[titleIndex][lang]}{" "}
      {TRANSLATE_TEXT.title2[lang]}
      <br />
      <span className={STYLES.title3}>{TRANSLATE_TEXT.title3[lang]}</span>
    </p>
  );
}

//prettier-ignore
const STYLES = {
  ct: "relative h-190 pt-36 flex flex-col items-center | sm:h-180 sm:pt-38 | md:h-145 md:pt-30 | lg:pt-32 lg:h-165 | xl:pt-36 xl:h-170",
  bg: "bg-cover bg-right | md:bg-center",

  title: "text-5xl mx-auto w-85 tracking-wide text-stronger text-center text-slate-300 | sm:w-auto sm:px-0 sm:text-6xl | md:text-5.5xl | lg:text-7xl ",
  title3: "text-kamai-light",

  subTitle: "w-70 rounded-md text-very-light text-lg text-slate-300 leading-relaxed text-shadow strongest text-center mt-10 tracking-wide | sm:w-100 sm:text-xl | md:mt-8 md:text-lg | lg:w-157 lg:mt-12 lg:text-xl | xl:text-1.5xl xl:w-175",

  arrow: "text-5.5xl animate-bounce text-slate-100 absolute bottom-20 cursor-pointer | sm:bottom-16 | md:bottom-8 || sLang<mt-34> ",
};

const BACKGROUND = {
  MOBILE: "https://i.ibb.co/LJrwBsm/kamai-web-cover-mobile.jpg",
  DESKTOP: "https://i.ibb.co/CPrt7yd/kamai-web-cover-desktop.jpg",
};

const TRANSLATE_TEXT = {
  title: {
    default: "Tu",
  },
  title2: {
    default: "Web",
  },
  title3: {
    default: "a medida",
  },
  subTitle: {
    default:
      "Sistema web o presentación digital, personalizada y profesional. En la nube y accesible desde cualquier parte.",
  },
};

const TITLES = [
  { default: "Página" },
  { default: "Sistema" },
  { default: "Tienda" },
  { default: "Espacio" },
  { default: "Portfolio" },
];

export default Cover;
