import React from "react";
import { BiArrowToTop } from "react-icons/bi";
import {
  BsBarChartSteps,
  BsQuestionCircle,
  BsEnvelopeFill,
} from "react-icons/bs";
import { IoMenu, IoClose } from "react-icons/io5";
import { animateScroll, scroller } from "react-scroll";

import { useIndicatedStyles } from "@static/tailwind";
import { KamaiIcon } from "@common/index";
import {
  useBordersVisibility,
  useBreakpoint,
  useFocusSensor,
  useSwipeDetector,
} from "@static/react";

function Top({ lang, setLang }) {
  const bp = useBreakpoint();
  const getActiveStyles = useIndicatedStyles(INDICATORS, DIRECTED_STYLES);

  const sidebarRef = React.useRef();
  const sidebarFocus = useFocusSensor(sidebarRef);
  const { direction, distance } = useSwipeDetector();
  const visible = useBordersVisibility({
    pixelsForVisibleTop: 10,
    pixelsForVisibleBottom: 50,
  });

  const arrowRef = React.useRef();

  React.useEffect(() => {
    //Blur the arrow button when reaching the top
    if (visible.top) arrowRef.current.blur();
  }, [visible.top]);

  React.useEffect(() => {
    if (distance > 170) sidebarFocus.setFocused(direction == "left");
  }, [direction, distance]);

  function goToTop() {
    if (visible.top) return;
    animateScroll.scrollToTop({ delay: 0, duration: 1000 });
  }

  function onLinkClick(to) {
    scroller.scrollTo(to, {
      smooth: true,
      duration: 1000,
      offset: -50,
    });
    sidebarFocus.setFocused(false);
  }

  const TogglerIcon = sidebarFocus.focused ? IoClose : IoMenu;
  const styles = getActiveStyles({ visible, sidebarFocus });

  return (
    <div className={styles.ct}>
      <div onClick={goToTop} className={styles.logo}>
        <KamaiIcon className={styles.logoIcon} />
        <span className={styles.logoName}>Kamai</span>
      </div>

      {bp.isDesktop() ? (
        <div className={styles.topRight}>
          {LINKS.map((ql) => (
            <button
              key={ql.section}
              onClick={() => onLinkClick(`section-${ql.section}`)}
              className={styles.topLink}
            >
              <ql.Icon className={styles.topLinkIcon} />
              {ql.text[lang]}
            </button>
          ))}

          {/* <div className={styles.topFlags}>
            {Object.keys(LANGS).map((langKey) => (
              <Flag
                key={langKey}
                emoji={LANGS[langKey].emoji}
                langKey={langKey}
                {...{ lang, setLang, visible, bp }}
              />
            ))}
          </div> */}
        </div>
      ) : (
        <>
          <button
            onClick={() => sidebarFocus.setFocused((focused) => !focused)}
          >
            <TogglerIcon className={styles.toggler} />
          </button>

          <div ref={sidebarRef} className={styles.sidebar}>
            <p className={styles.sidebarTitle}>
              {TRANSLATE_TEXT.scrollTo[lang]}
            </p>

            {LINKS.map((ql) => (
              <button
                key={ql.section}
                onClick={() => onLinkClick(`section-${ql.section}`)}
                className={styles.sideLink}
              >
                {ql.text[lang]}
                <ql.Icon className={styles.sideLinkIcon} />
              </button>
            ))}

            <div className={styles.sideOrnament}>
              <div className={styles.sideOrnamentInside} />
            </div>

            {/* <p className={styles.sidebarLang}>
              {TRANSLATE_TEXT.language[lang]}
            </p> */}

            {/* {Object.keys(LANGS).map((langKey) => (
              <Flag
                key={langKey}
                emoji={LANGS[langKey].emoji}
                langKey={langKey}
                {...{ lang, setLang, visible, bp }}
              />
            ))} */}
          </div>
        </>
      )}

      <button ref={arrowRef} onClick={goToTop} className={styles.arrow}>
        <BiArrowToTop className={styles.arrowIcon} />
      </button>
    </div>
  );
}

//prettier-ignore
const DIRECTED_STYLES = {
  ct: "z-60 fixed w-full flex justify-between px-4 py-4 items-center transition-all duration-700 | sm:px-6 | lg:pr-8 | xl:pr-10 xl:pl-8 || nt<bg-kamai'py-2'bg-opacity-95'shadow-md>",
  
  logo: "relative flex items-center text-3xl text-strong tracking-wider cursor-pointer transition-all duration-700 | md:text-2.5xl | lg:text-3xl || nt<text-2xl'sm:text-3xl'md:text-2xl'lg:text-3xl>",
  logoIcon: "w-9 h-9 mr-3 shrink-0 text-slate-200 transition-all duration-700 | md:w-8 md:h-8 | lg:w-9 lg:h-9 || nt<w-8'h-8'sm:w-9'sm:h-9'md:w-7'md:h-7'lg:w-9'lg:h-9>",
  logoName: "text-slate-200",

  toggler: "text-4.5xl text-slate-200 transition-all duration-700 || nt<text-4xl'sm:text-4.5xl>",

  sidebar: "fixed overflow-hidden z-100 flex flex-col shadowed-box top-17 right-0 pl-5 pr-4 pt-8 h-screen w-60 bg-white transition-all duration-700 translate-x-100 rounded-l-md | sm:w-65 sm:top-18 sm:pl-6 sm:pr-5 sm:pt-12 || nt<top-13'sm:top-15> sbo<translate-x-0>",
  sidebarTitle: "text-center text-base text-kamai-light | sm:text-lg",
  sidebarLang: "text-center text-base text-sky-600 mt-8 mb-2",

  sideLink: "flex justify-between mt-6 border-b-1 border-slate-400 border-opacity-80 pb-1 text-lg items-center text-light text-slate-600 cursor-pointer hover:text-sky-500 focus:text-sky-500 transition duration-300 | sm:mt-8",
  sideLinkIcon: "mr-2 mt-1px text-1.5xl text-kamai",
 
  sideOrnament: "absolute h-100 w-100 bg-kamai-light bg-opacity-30 bottom-20 -left-5 rounded-full | sm:bottom-40",
  sideOrnamentInside: "absolute w-100 h-100 top-15 left-0 bg-slate-100 rounded-full",
 
  // sideOrnament: "absolute flex justify-center items-center h-100 w-100 bg-kamai-light bg-opacity-30 -bottom-30 -left-10 rounded-full",
  // sideOrnamentInside: "absolute w-30 h-30 -top-10 left-0 bg-kamai-light bg-opacity-20 rounded-full",

  arrow: "bg-kamai bg-opacity-80 text-slate-100 rounded-sm items-center p-1 opacity-0 cursor-default border-2 border-kamai-light border-opacity-0 transition-all duration-1000 translate-x-20 hover:bg-kamai-light focus:border-opacity-100 fixed bottom-4 right-4 | sm:bottom-6 sm:right-6 || nt<opacity-1'cursor-pointer'translate-x-0>",
  arrowIcon: "text-2.5xl | sm:text-3.5xl",

  //Desktop only

  topRight: "flex items-center",
  topLink: "flex justify-center items-center text-default text-slate-200 ml-4 text-sm cursor-pointer border-b-1 border-slate-200 py-1 border-opacity-0 transition duration-500 hover:border-opacity-80 focus:border-opacity-80 | lg:ml-8 lg:text-lg | xl:ml-10",
  topLinkIcon: "mr-2 text-base mt-1px | lg:text-xl | xl:text-1.5xl",
  topFlags: "flex | md:ml-4 md:border-l-1 md:border-slate-300 md:border-opacity-50 | lg:ml-6 lg:pl-2 || nt<md:border-slate-600>",
};

const INDICATORS = [
  { key: "notTop", directive: "nt", condition: (p) => !p.visible.top },
  { key: "bottom", directive: "bo", condition: (p) => p.visible.bottom },
  {
    key: "sidebarOpened",
    directive: "sbo",
    condition: (p) => p.sidebarFocus.focused,
  },
];

function Flag({ emoji, langKey, lang, visible, setLang, bp }) {
  const getActiveStyles = useIndicatedStyles(FLAG_INDICATORS, FLAG_DIR_STY);

  const styles = getActiveStyles({ lang, visible, langKey });

  return (
    <button onClick={() => setLang(langKey)} className={styles.ct}>
      {FLAG_LANGUAGE[langKey]}
      <span className={styles.emoji}>{emoji}</span>
    </button>
  );
}

const FLAG_DIR_STY = {
  ct: "mt-2 leading-tight text-center text-slate-600 flex justify-between items-center text-lg border-1 pl-3 pr-2 rounded-md py-1 border-slate-300 transition duration-500 cursor-pointer | md:rounded-none md:border-transparent md:border-x-0 md:border-t-0 md:p-0 md:justify-center md:ml-2 md:text-sm md:mt-0 md:text-slate-300 md:border-b-1 md:ml-2 | lg:border-b-2 lg:ml-4 lg:text-lg lg:leading-normal || nt<text-slate-600'md:text-slate-600> se<border-sky-500'md:border-sky-500'border-opacity-100'md:border-opacity-80> ",
  emoji:
    "text-2xl mr-2 | md:mr-0 md:ml-1 md:text-lg md:w-auto | lg:text-xl lg:ml-2",
};

const FLAG_INDICATORS = [
  { key: "notTop", directive: "nt", condition: (p) => !p.visible.top },
  { key: "selected", directive: "se", condition: (p) => p.lang == p.langKey },
];

const FLAG_LANGUAGE = {
  default: "English",
  spanish: "Español",
};

const LINKS = [
  {
    text: { default: "¿Por qué una Web?" },
    Icon: BsQuestionCircle,
    section: "why",
  },
  {
    text: { default: "¿Por qué Kamai?" },
    Icon: KamaiIcon,
    section: "vision",
  },
  {
    text: { default: "Pasos para tu Web" },
    Icon: BsBarChartSteps,
    section: "steps",
  },
  {
    text: { default: "Contactanos" },
    Icon: BsEnvelopeFill,
    section: "contact",
  },
];

const TRANSLATE_TEXT = {
  scrollTo: {
    default: "Ir a la sección...",
  },
  language: {
    default: "Idioma",
  },
};

export default Top;
