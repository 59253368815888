import React from "react";

function KamaiIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 227.000000 188.000000"
      preserveAspectRatio="xMidYMid meet"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...props}
    >
      <g
        transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
        stroke="currentColor"
        fill="currentColor"
      >
        <path
          d="M990 1874 c-387 -33 -624 -123 -788 -299 -147 -157 -208 -359 -183
 -614 44 -465 327 -799 776 -916 244 -63 543 -53 780 26 338 113 562 348 652
 684 24 89 26 118 27 275 1 196 -9 250 -69 375 -114 238 -371 396 -727 449
 -104 16 -382 27 -468 20z m-441 -379 c188 -45 335 -178 414 -375 35 -88 64
 -241 72 -380 4 -63 11 -123 16 -132 5 -10 24 -25 41 -34 39 -20 87 -12 118 21
 22 23 25 38 31 158 24 420 187 672 480 742 109 25 121 23 187 -43 109 -110
 152 -225 152 -413 0 -247 -68 -426 -220 -579 -118 -120 -265 -195 -461 -237
 -116 -24 -362 -24 -479 1 -345 73 -584 294 -660 609 -63 260 -22 473 118 617
 66 68 79 71 191 45z"
        />
        <path
          d="M585 1095 c-178 -64 -130 -329 60 -329 98 0 168 70 168 170 0 121
 -114 201 -228 159z"
        />
        <path
          d="M1565 1091 c-152 -70 -136 -278 25 -321 72 -19 160 21 195 91 19 36
 19 112 1 147 -31 59 -99 102 -161 102 -11 0 -38 -9 -60 -19z"
        />
      </g>
    </svg>
  );
}

export default KamaiIcon;
