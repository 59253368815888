import React from "react";
import { chunk } from "lodash";
import { Element as ScrollableContainer, scroller } from "react-scroll";
import { GiAnvil } from "react-icons/gi";
import { AiOutlineDollar, AiOutlineFieldTime } from "react-icons/ai";
import { BsCodeSlash, BsGlobe } from "react-icons/bs";
import { BsBrush } from "react-icons/bs";
import { IoHourglassOutline } from "react-icons/io5";
import { MdOutlinePhonelink, MdOutlineMobileOff } from "react-icons/md";

import { BgContainer, KamaiIcon, CuteButton } from "@common/index";
import { useBreakpoint } from "@static/react";

function Vision({ lang }) {
  const bp = useBreakpoint();

  function onButton() {
    scroller.scrollTo("section-steps", {
      smooth: true,
      duration: 1000,
      offset: -70,
    });
  }

  function buildItemsLayout(itemsArray, isGood) {
    if (bp.isDesktop())
      return chunk(itemsArray, 2).map((row, index) => (
        <div key={index} className={STYLES.itemsRow}>
          {row.map((item, index) => (
            <Item {...item} {...{ lang, isGood }} key={index} />
          ))}
        </div>
      ));
    else
      return itemsArray.map((item, index) => (
        <Item {...item} {...{ lang, isGood }} key={index} />
      ));
  }

  return (
    <ScrollableContainer
      name="section-vision"
      style={{
        backgroundImage: "linear-gradient(to right, #eaeaea, #e5e5e5)",
      }}
      className={STYLES.ct}
    >
      <p className={STYLES.title}>{TRANSLATE_TEXT.title[lang]}</p>
      <div className={STYLES.separator + STYLES.separatorBad} />

      {buildItemsLayout(ITEMS.BAD, false)}

      <ScrollableContainer name="our-solution" className={STYLES.title}>
        {TRANSLATE_TEXT.title2[lang]}
      </ScrollableContainer>
      <div className={STYLES.separator + STYLES.separatorGood} />

      {buildItemsLayout(ITEMS.GOOD, true)}

      <CuteButton
        Icon={BsGlobe}
        straightStyles={{
          button: "relative z-10 mx-auto mt-12 -mb-4 | lg:mt-16",
        }}
        color="sky"
        colorsBehavior="always-filled"
        size="larger"
        onClick={onButton}
      >
        {TRANSLATE_TEXT.button[lang]}
      </CuteButton>

      <BgContainer bgClassName={STYLES.bottomImg} imgSrc={BACKGROUND} />
      {/* <KamaiIcon className={STYLES.bottomKamai} /> */}

      <div className={STYLES.ornament}>
        <div
          style={{ backgroundColor: "#e3e3e3" }}
          className={STYLES.ornamentInside}
        />
      </div>
      <KamaiIcon className={STYLES.ornament2} />
      <div className={STYLES.ornament3}>
        <div
          style={{ backgroundColor: "#e2e2e2" }}
          className={STYLES.ornament3Inside}
        />
      </div>
    </ScrollableContainer>
  );
}

function Item({ Icon, title, text, isGood, lang }) {
  return (
    <div className={STYLES.item}>
      <p className={STYLES.itemTitle}>
        <Icon
          className={
            STYLES.itemIcon +
            (isGood ? STYLES.itemIconGood : STYLES.itemIconBad)
          }
        />{" "}
        {title[lang]}
      </p>
      {text[lang]}
    </div>
  );
}

//prettier-ignore
const STYLES = {
  ct: "relative flex flex-col overflow-hidden",

  title: "z-20 text-center mt-12 text-slate-600 text-strong text-3xl w-80 mx-auto | sm:w-135 sm:px-0 | md:w-150 | lg:text-3.5xl",
  separator: "mt-6 w-40 mb-2 border-t-2 mx-auto | sm:mb-4 ",
  separatorBad: "border-red-600 border-opacity-70",
  separatorGood: "border-kamai border-opacity-80",
  
  item: "z-20 mt-6 text-slate-600 text-very-light w-85 mx-auto pl-12 | sm:pl-16 sm:w-130 sm:text-lg sm:mt-8 | md:mt-0 md:mx-4 md:w-100 | lg:mx-6 lg:w-120 | xl:mx-8 xl:text-xl xl:w-140",
  itemTitle: "flex items-center text-strong text-xl -ml-12 | sm:text-1.5xl sm:-ml-17 | md:text-xl md:-ml-12 | lg:-ml-15 lg:text-1.5xl | xl:-ml-17",
  itemIcon: "text-3xl mr-4 mt-2 | sm:mr-6 sm:text-4.5xl | md:text-3.5xl md:mr-4 | lg:mr-6 lg:text-4xl | xl:text-4.5xl ",
  itemIconBad: "text-red-600 text-opacity-70",
  itemIconGood: "text-kamai",
  
  ornament: "z-10 absolute -left-35 top-2 bg-red-500 bg-opacity-20 w-50 h-50 rounded-full | sm:top-5 sm:left-auto sm:-right-25",
  ornamentInside: "absolute w-50 h-50 -top-20 -right-10 rounded-full | sm:right-auto sm:-left-10",
  
  ornament2: "z-10 absolute -right-15 top-200 text-kamai text-opacity-30 w-30 h-30 | sm:w-40 sm:h-40 sm:-left-17 sm:top-185 sm:right-auto | md:top-120 | lg:top-110",
  
  ornament3: "z-10 absolute flex justify-center items-center -left-50 -bottom-20 bg-kamai bg-opacity-20 w-80 h-80 rounded-full | sm:w-90 sm:h-90 sm:left-auto sm:-right-50 | md:-right-70 md:w-140 md:h-140 | lg:-right-80 lg:-bottom-5",
  ornament3Inside: "absolute w-60 h-60 rounded-full | sm:top-10 | md:w-100 md:h-100",

  bottomImg: "z-0 bg-cover bg-left bg-no-repeat h-80 | md:h-100 | lg:h-120 | xl:h-140",
  // bottomKamai: "absolute w-14 h-14 text-kamai text-opacity-50 bottom-24 left-58",

  //Landscape only

  itemsRow: "flex justify-center mt-8"
};

const TRANSLATE_TEXT = {
  title: {
    default: "Problemas comunes en páginas hoy",
  },
  title2: {
    default: "Nuestra solución",
  },
  button: {
    default: "Quiero adquirir una Web",
  },
};

const BACKGROUND = "https://i.ibb.co/854pKQj/kamai-web-vision.jpg";

const ITEMS = {
  BAD: [
    {
      title: { default: "Sobrecarga innecesaria" },
      Icon: GiAnvil,
      text: {
        default:
          "Suelen llenarse de animaciones innecesarias y herramientas cargadas que las hacen demasiado pesadas.",
      },
    },
    {
      title: { default: "Lentas y poco fluidas" },
      Icon: IoHourglassOutline,
      text: {
        default:
          "Por sobrecargarse, se vuelven muy lentas, dando una experiencia torpe, frustrante y poco fluida.",
      },
    },
    {
      title: { default: "Costosas de personalizar" },
      Icon: AiOutlineDollar,
      text: {
        default:
          "Muchas páginas son muy costosas de personalizar y mantener por haber sido construidas con herramientas externas pesadas y caras.",
      },
    },
    {
      title: { default: "Sin adaptación responsiva" },
      Icon: MdOutlineMobileOff,
      text: {
        default:
          "Las herramientas comunes generan páginas que no se adaptan a la inmensidad de dispositivos de hoy, en los cuales se ven rotas e inutilizables.",
      },
    },
  ],
  GOOD: [
    {
      title: { default: 'Páginas "artesanales"' },
      Icon: BsCodeSlash,
      text: {
        default:
          "La programación de las páginas es hecha desde la base, haciéndolas muy estables y completamente personalizables hasta el último detalle.",
      },
    },
    {
      title: { default: "Diseño ligero y elegante" },
      Icon: BsBrush,
      text: {
        default:
          "Nuestro diseñador es profesional en la construcción de páginas atractivas sin la necesidad de sobrecargarlas de animaciones ni diseños pesados.",
      },
    },
    {
      title: { default: "Fluidas y rápidas" },
      Icon: AiOutlineFieldTime,
      text: {
        default:
          "Nuestras web son sumamente ligeras y de carga rápida, dando a los visitantes una experiencia muy fluida y profesional.",
      },
    },
    {
      title: { default: "Todos los dispositivos" },
      Icon: MdOutlinePhonelink,
      text: {
        default:
          "Las páginas de Kamai están diseñadas para incluir tamaño teléfono, tablet y cualquier dispositivo, no sólo computadoras.",
      },
    },
  ],
};

export default Vision;
