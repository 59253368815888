import React from "react";
import { chunk } from "lodash";
import { Element as ScrollableContainer, scroller } from "react-scroll";
import { FaHandshake } from "react-icons/fa";
import { BsQuestionLg } from "react-icons/bs";

import { KamaiIcon } from "@common/index";
import { useBreakpoint } from "@static/react";

import { BREAKPOINTS } from "@static/values/keys";

function Experience({ lang }) {
  const bp = useBreakpoint();

  function onLinkClick(section) {
    scroller.scrollTo(section, { smooth: true, duration: 1000, offset: -70 });
  }

  const iboxesLayout = bp.isWiderThan(BREAKPOINTS.LANDSCAPE) ? (
    //Laptop and wider
    <div className={STYLES.iboxes}>
      {INFO_BOXES.map((ib) => (
        <InfoBox key={ib.to} {...ib} {...{ lang, onLinkClick }} />
      ))}
    </div>
  ) : bp.isWiderThan(BREAKPOINTS.TABLET) ? (
    //Landscape
    <div className={STYLES.iboxes}>
      {chunk(INFO_BOXES, 2).map((row, index) => (
        <div className={STYLES.iboxRow} key={index}>
          {row.map((ib) => (
            <InfoBox key={ib.to} {...ib} {...{ lang, onLinkClick }} />
          ))}
        </div>
      ))}
    </div>
  ) : (
    //Tablet and narrower
    <div className={STYLES.iboxes}>
      {INFO_BOXES.map((ib) => (
        <InfoBox key={ib.to} {...ib} {...{ lang, onLinkClick }} />
      ))}
    </div>
  );

  return (
    <ScrollableContainer name="section-experience" className={STYLES.ct}>
      <p className={STYLES.title}>{TRANSLATE_TEXT.title[lang]}</p>

      <div className={STYLES.separator} />

      {iboxesLayout}

      <div className={STYLES.ornament}>
        <div className={STYLES.ornamentInside} />
      </div>

      <div className={STYLES.ornament2}>
        <div className={STYLES.ornament2Inside} />
      </div>

      <div className={STYLES.ornament3}>
        <div className={STYLES.ornament3Inside} />
      </div>
    </ScrollableContainer>
  );
}

function InfoBox({ to, Icon, title, text, button, lang, onLinkClick }) {
  return (
    <div className={STYLES.ibox}>
      <div className={STYLES.iboxIconCt}>
        <Icon className={STYLES.iboxIcon} />
      </div>
      <p className={STYLES.iboxTitle}>{title[lang]}</p>
      <p className={STYLES.iboxText}>{text[lang]}</p>
      <button onClick={() => onLinkClick(to)} className={STYLES.iboxButton}>
        {button[lang]}
      </button>
    </div>
  );
}

//prettier-ignore
const STYLES = {
  ct: "relative pt-12 pb-14 bg-gray-100 flex flex-col items-center overflow-hidden",
  title: "z-50 text-center text-slate-600 text-strong text-3.5xl w-90 | sm:w-100 | lg:text-4xl lg:w-auto",
  separator: "z-50 mt-8 w-30 border-t-2 border-kamai mx-auto",

  iboxes: "z-50 mt-8 flex flex-col | sm:px-12 | md:px-4 md:self-stretch | lg:flex-row lg:justify-center",

  ibox: "relative my-4 bg-slate-100 py-6 px-6 mx-4 flex flex-col items-center lightly-shadowed-box rounded-xl | sm:py-8 sm:w-130 | md:h-87 md:mx-4 md:w-90 | lg:px-4 | xl:h-95",
  iboxIconCt: "text-4.5xl text-kamai text-opacity-80 border-kamai border-opacity-40 border-1 p-5 rounded-full | md:text-4xl | xl:text-4.5xl xl:p-6",
  iboxIcon: "",
  iboxTitle: "mt-4 text-slate-700 text-strong text-xl | sm:text-1.5xl | md:text-xl | xl:text-1.5xl",
  iboxText: "mt-6 text-base text-center text-very-light text-slate-600 leading-relaxed | sm:text-lg | md:text-base | xl:text-lg",
  iboxButton: "mt-6 text-base text-default rounded-md py-2 px-12 bg-sky-500 hover:bg-opacity-80 transition duration-300 text-slate-100 shadow-md | md:text-sm md:px-10 md:absolute md:bottom-6 | lg:text-base lg:px-12",

  ornament: "z-10 absolute top-35 -left-30 w-60 h-60 bg-kamai rounded-full bg-opacity-20 | sm:top-30 sm:w-70 sm:h-70 | md:-left-40 md:top-40 md:w-90 md:h-90 | lg:w-140 lg:h-140 lg:-left-20 | xl:w-160 xl:h-160",
  ornamentInside: "absolute -bottom-10 right-0 w-60 h-60 bg-slate-100 rounded-full | md:top-20 md:right-0 md:w-80 md:h-80 | lg:top-15 lg:-right-2 lg:w-130 lg:h-130 | xl:right-2 xl:top-20 xl:w-140 xl:h-140",

  ornament2: "z-10 absolute top-100 -right-20 w-60 h-60 bg-kamai rounded-full bg-opacity-20 | sm:-right-10 sm:top-90 sm:w-90 sm:h-90 sm:top-60 | md:top-15 md:w-150 md:h-130 | xl:w-170 xl:h-150",
  ornament2Inside: "absolute -top-30 w-75 h-75 bg-slate-100 rounded-full | sm:left-10 sm:-top-20 | md:left-0 md:-top-15 md:w-150 md:h-110 | xl:w-170 xl:h-140",

  ornament3: "z-10 absolute -bottom-40 -left-50 w-100 h-100 bg-kamai rounded-full bg-opacity-20 | sm:w-120 sm:h-120 | lg:z-20 lg:w-70 lg:h-70 lg:bg-slate-100 lg:bg-opacity-100 lg:-left-35 lg:-bottom-35",
  ornament3Inside: "absolute right-20 bottom-30 w-60 h-60 bg-slate-100 rounded-full | sm:bottom-25 sm:right-30 sm:w-80 sm:h-80 | lg:w-0 lg:h-0",

  //Landscape only

  iboxRow: "flex justify-center",
};

const TRANSLATE_TEXT = {
  title: {
    default: "Tu sitio, exactamente como lo querés.",
  },
};

const INFO_BOXES = [
  {
    Icon: KamaiIcon,
    title: { default: "¿Por qué Kamai?" },
    text: {
      default:
        "Nos enfocamos en producir páginas web personalizadas, sin herramientas genéricas y sin limitaciones.",
    },
    button: { default: "Nuestra solución" },
    to: "our-solution",
  },
  {
    Icon: BsQuestionLg,
    title: { default: "¿Por qué necesito una Web?" },
    text: {
      default:
        "Te invitamos a revisar por qué una página web es imprescindible hoy y por qué te haría más productivo.",
    },
    button: { default: "¿Por qué invertir?" },
    to: "section-why",
  },
  {
    Icon: FaHandshake,
    title: { default: "Entrega a tiempo." },
    text: {
      default:
        "Nos comprometemos a entregarte tu página lista para compartir en el tiempo prometido.",
    },
    button: { default: "Adquirir una Web" },
    to: "section-steps",
  },
  // {
  //   Icon: BsRocketTakeoff,
  //   title: { default: "Potencial sin riesgo" },
  //   text: {
  //     default:
  //       "Nuestra forma de servicio y precios suponen una inversión sin riesgo para todos y con un alto potencial.",
  //   },
  //   button: { default: "Contáctanos" },
  //   to: "section-services",
  // },
];

export default Experience;
